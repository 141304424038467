#auth {
   height: 100vh;
   width: 100vw;
   background: url('../../assets/images/background.png');
   background-position: center;
   background-repeat: repeat;
   background-size: 5%;
   display: flex;
   background-color: #fff;
}

.card-login {
   border: none;
   border-radius: 5px;
   padding: 13px 25px;
   width: 350px;
}

.text-wiicab {
   color: #e83c00;
}

hr {
   border-top: 1px solid rgba(0, 0, 0, 0.2);
   margin: 0.5rem 0rem 0.5rem 0rem;
}

.fa-eye-slash,
.fa-eye {
   top: 34px;
   right: 16px;
   cursor: pointer;
}

.forgot-link,
.help-link {
   font-size: 0.8rem;
}

.forgot-link:hover {
   text-decoration: none;
   color: #828282;
   transition: all 0.2s ease-in-out;
}

.help-link {
   color: #828282;
}

.help-link:hover {
   text-decoration: none;
   color: #e83c00;
   transition: all 0.2s ease-in-out;
}

.header-link {
   font-size: 1.1rem;
   font-weight: 600;
}
